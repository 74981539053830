import { useFormik } from 'formik';
import JoditEditor from 'jodit-react';
import React, { useEffect, useMemo, useRef, useState } from 'react'
import toast from 'react-hot-toast';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { addproductSizeApi, getProductSizeApi, createOrUpdateCategoryApi, createOrUpdateSubCategoryApi, createProductApi, getAllCategoryAndSubCategoryApi, uploadImage } from '../../../../utils/ApiEndpointFunctions';
import { ImageUrl } from '../../../../utils/BaseUrl';
import Header from '../../../common/Header';
import Spinwheel from '../../../common/Spinwheel';
import { addProductInitialValue, addProductValidationSchema, categoryInitialValue, categoryValidationSchema, subCategoryInitialValue, subCategoryValidationSchema } from '../../../common/Validation';
import { SIZES } from '../../../../utils/HelperFuncitons';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import Category from '../../category/modal/Category';
import SubCategory from '../../category/modal/SubCategory';

function ProductAdd() {
  const navigate = useNavigate()
  const [addSpin, setAddSpin] = useState(false)
  const [videoUpload, setVideoUpload] = useState(null);
  const location = useLocation();
  const [categoriesOptions, setCategoryOptions] = useState()
  const [subCategoryOptions, setSubCategoryOptions] = useState()
  const [submitLoader,setSubmitLoader] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState({_id:null})
  const params = useParams();
  const [sizeOptions, setSizeOptions] = useState([]);
  const [fileTypeError, setFileTypeError] = useState({ image: "", video: "" })
  let path = location.pathname
  path = path.split("/")?.filter((e) => {
    if (e === "add" || e === "update") {
      return true
    }
    return false;
  })[0];

  path = (path[0].toUpperCase() + path.slice(1));
  const categoryModalId = "categoryModalId"
  const subCategoryModalId = "subCategoryModalId"

  const getproductSize = async () => {
    try {
      const res = await getProductSizeApi()
      if (res.status === 200) {
    
        setSizeOptions(res.data?.productSize?.map(ele => ({label: ele.name, value: ele.name})))
      }
    } catch (error) {
      console.log(error)
    }
  }

  const addproductSize = async(name) => {
    try {
      const res = await addproductSizeApi({name:name})
      if (res.status === 200) {
        toast.success(res.data?.message)
        getproductSize()
      }
    } catch (error) {
      console.log(error)
    }
  }

 

  const editor = useRef(null);
  const config = useMemo(() => {
    return {
      disablePlugins: ['Find', "Preview", "fullsize", "Change", "About", 'Print', "image", "file", "spellcheck", "selectall", "copyformat", "table", "hr", "symbols", "lineHeight", "classSpan", "paragraph"],
      removeButtons: ['undo', 'redo', 'Print', 'find', "video", "cut", "copy", "paste", "image", "source", "symbols", "lineHeight", "selectall", "paragraph", "superscript", "subscript"],
      readonly: false,
      placeholder: 'Start writing...',
      askBeforePasteFromWord: false,
      askBeforePasteHTML: false,
      enter: "br",
      hidePoweredByJodit: true

    }
  }, []
  );

  const categoryFormik = useFormik({
    initialValues: categoryInitialValue,
    onSubmit: async (values) => {
      try {
        setSubmitLoader(true)
        const res = await createOrUpdateCategoryApi(values)
        if(res.status === 200) {
          toast.success(res.data?.message)
          
          document.getElementById(categoryModalId).click()
          getAllCategoryAndSubCategory();
        }
      } catch (error) {
        console.log(error)
        toast.error("Something went wrong.")
      }

      setSubmitLoader(false)
    },
    validationSchema: categoryValidationSchema,
  })


  const subCategoryFormik = useFormik({
    initialValues: subCategoryInitialValue,
    onSubmit: async (values) => {
      try {
        setSubmitLoader(true)
        const payload = {...values, categoryId: selectedCategory._id};
        const res = await createOrUpdateSubCategoryApi(payload);
        if(res.status === 200) {
         
          getAllCategoryAndSubCategory();
          
          toast.success(res.data?.message)
          document.getElementById(subCategoryModalId).click()
        }

      } catch (error) {
       console.log(error) 
       toast.error("Something went wrong")
      }
      setSubmitLoader(false)
    },
    validationSchema: subCategoryValidationSchema
  })

  const handleSubmit = async (values) => {
    try {
      const payload = JSON.parse(JSON.stringify(values));
      payload.products = payload.products?.map((ele, index) => {
        return {...ele, category: ele.category?._id, subcategory: ele.subcategory?._id}
      })
      
      let res
      setAddSpin(true)
      res = await createProductApi(payload)
      if (res?.status === 200) {
        toast.success(res.data?.message)
        navigate(-1)
      }
      setAddSpin(false)
    } catch (error) {
      error?.response?.data?.message && toast.error(error?.response?.data?.message);
      setAddSpin(false)
    }
  }


  const handleThumbnail = async (index, e) => {
    const types = ['image/jpeg', 'image/png', 'image/gif']
    const file = e.target.files
    setFileTypeError({ ...fileTypeError, image: "" });
    if (file?.length > 0) {
      const formData = new FormData();
      file?.forEach((ele, index) => {
        formData.append(`file`, ele);
      })

      formData.append("type", 3);
      formData.append("folder", "product")
      const res = await uploadImage('/users/multipleFileUpload', formData)
      handleChapterBlur(index, "productImage")
      if (res.status === 200 && res.data?.status === 1) {
        const fileUrls = res.data?.data?.map((ele, index) => {
          return ele.fileName
        })
        formik.setFieldValue(`products[${index}].productImage`, [...formik.values.products[index].productImage, ...fileUrls]);
        toast.success("File uploaded");
      }
    }
  }

  const getAllCategoryAndSubCategory = async () => {
    try {
      const res = await getAllCategoryAndSubCategoryApi()
      if (res.status === 200) {
        
        const category = res.data?.data?.map((ele, index) => {
          return { label: ele.name, value: ele }
        })
        
        setCategoryOptions(category)
        
        // for fetching all subCategories of that selected category
        if(selectedCategory?._id){
          const subCategory = res.data?.data?.filter(ele=>ele?._id==selectedCategory?._id)
        
          if(subCategory?.[0]?.subCategory){
            setSubCategoryOptionsHandler(subCategory?.subCategory)
            
          }
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const setSubCategoryOptionsHandler = (subCategory) => {
    
    setSubCategoryOptions(subCategory?.map((ele, index) => {
      return { label: ele.name, value: ele }
    }))
    
  }

  const colorImageUpload = async (e, index, index2) => {
    const file = e.target.files[0];
    const types = ['image/jpeg', 'image/png', 'image/gif']
    if (file) {
      if (types.includes(file?.type)) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("type", 3)
        formData.append("folder", "product")
        const res = await uploadImage('/users/fileUpload', formData)
        if (res.status === 200 && res.data?.status === 1) {
          formik.setFieldValue(`products[${index}].color[${index2}].colorImage`, res.data?.data?.fileName)
          toast.success("File uploaded")
        }
      } else {
        formik.setFieldValue(`products[${index}].color[${index2}].colorImage`, "")
        setTimeout(() => {
          toast.error("Must be image only")
        }, 1000)

      }
    }
  }

  const addNew = (e) => {
    e.preventDefault();
    formik.setFieldValue("products", [...formik.values?.products, {
      name: "",
      category: "",
      subcategory: "",
      price: "",
      discount: "",
      productImage: [],
      desc: "",
      size: [],
      color: [{ color: "", colorImage: "" }],
      remainingIs: "",
    }])
  }
  const remove = (e, index) => {
    e.preventDefault()
    formik.setFieldValue("products", formik.values.products?.filter((ele, i) => {
      if (i === index) return false;
      return true;
    }))
  }
  const addNewColor = (e, index,) => {
    e.preventDefault();
    formik.setFieldValue(`products[${index}].color`, [...formik.values?.products[index].color, {
      color: "",
      colorImage: ""
    }])
  }

  const removeColor = (e, index, index2) => {
    e.preventDefault()
    formik.setFieldValue(`products[${index}].color`, formik.values.products[index]?.color?.filter((ele, i) => {
      if (i === index2) return false;
      return true;
    }))
  }

  const handleChapterChange = (index, fieldName, value) => {
    formik.setFieldValue(`products[${index}].${fieldName}`, value)

    if (fieldName === "deletedPrice" || fieldName === "price") {


      let discount = fieldName === "deletedPrice" ? (( value - formik.values.products[index].price) / value) * 100
        : ((formik.values.products[index].deletedPrice - value) /formik.values.products[index].deletedPrice ) * 100;

      if (!value || !formik.values.products[index].price || !formik.values.products[index].deletedPrice) discount = 0;
   
      formik.setFieldValue(`products[${index}].discount`, isNaN(discount) ? 0 : Number(discount).toFixed(2))
    }
  }

  const handleChapterBlur = (index, fieldName) => {
    formik.setFieldTouched(`products[${index}].${fieldName}`, true)
  }


  useEffect(() => {
    if ((path === "Update") && location.state == null) {
      navigate("/course/chapter");
    }
    getAllCategoryAndSubCategory();
    getproductSize();
  }, [])

  const formik = useFormik({
    initialValues: { ...addProductInitialValue, vendorId: params?.vendorId },
    onSubmit: handleSubmit,
    validationSchema: addProductValidationSchema,
  });

  return (
    <>
      <div className="main-content vh-100 vh_md-auto overflow-y-a">
        <Header title={params?.vendorId ? "Vendor" : "Shop"} />
        <div className="container-fluid">
          <div className="row">
            <nav className="mb-5">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">
                    <i className="fe fe-home"></i> Dashboard
                  </Link>
                </li>
                {params?.vendorId && <li className="breadcrumb-item">
                  <Link to="/vendor" className="breadcrumb-item">
                    Vendor
                  </Link>
                </li>}
                <li className="breadcrumb-item active" aria-current="page">
                  Add-product
                </li>
              </ol>
            </nav>
            {/* <div className='d-flex justify-content-between'>
              <h2 className="report">Product details</h2>
              <div className=''>
                <button type="submit" data-bs-toggle="modal" data-bs-target={`#${categoryModalId}`} className="btn main-color fw-bold mx-1" >Category</button>
                <button className="btn main-color fw-bold mx-1" data-bs-toggle={selectedCategory?._id && "modal"} data-bs-target={`#${subCategoryModalId}`} 
                onClick={() => {selectedCategory?._id ? subCategoryFormik.resetForm() : toast('Please select a category first', {icon: '⚠️',}); }} >Sub category</button>
              </div>
            </div> */}
            <hr />

            <div className="col-lg-11" style={{ zIndex: "1" }}>
              <form action="" onSubmit={formik.handleSubmit} >
                {formik.values.products?.map((ele, index) =>
                  <div key={index}>
                    {formik.values.products?.length === (index + 1) && <div className=" text-end mb-3">
                      <button type="button" className="me-3 plusButton" onClick={addNew}><span>+</span></button>
                      {formik.values.products?.length !== 1 && <button type="button" className=" plusButton" onClick={(e) => { remove(e, index) }}><span>-</span></button>}
                    </div>}

                    <div className="card ">
                      <div className="card-body" style={{ backgroundColor: '#ebe3d93b' }}>
                        <div className="row mb-3">
                          <div className="col-lg-6">
                            <label
                              className="form-label text-start d-block"
                              htmlFor={"productName" + index}
                            >
                              Name
                            </label>
                            <div className="position-relative">
                              <input className="form-control" type="text" id={"productName" + index} placeholder="Enter name" name="name" value={formik.values.products[index].name} onChange={(e) => { handleChapterChange(index, "name", e?.target?.value) }} onBlur={() => { handleChapterBlur(index, "name") }} />
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-door-fill input-icon" viewBox="0 0 16 16">
                                <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
                                <path d="M3 8.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0-5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-1z" />
                              </svg>
                              {formik.touched.products?.[index]?.name && formik.errors.products?.[index]?.name ? (<div className='req-error'>{formik.errors.products?.[index]?.name}</div>) : null}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <label
                              className="form-label text-start d-block"
                              htmlFor={"Categories" + index}
                            >
                              Status
                            </label>
                            <select name="status" id={"statusstatus" + index} className="form-select form-control border" value={formik.values?.products?.[index]?.status} onChange={(e) => { handleChapterChange(index, "status", e.target.value) }} onBlur={() => { handleChapterBlur(index, "status") }} >
                              <option value="">Choose status</option>
                              <option value="In stock">In stock</option>
                              <option value="Available">Available</option>
                              <option value="Sold out">Sold out</option>
                              <option value="Not available">Not available</option>
                            </select>
                            {formik.touched?.products?.[index]?.status && formik.errors?.products?.[index]?.status ? (<div className='req-error'>{formik.errors?.products?.[index]?.status}</div>) : null}
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-lg-6">
                            <label
                              className="form-label text-start d-block"
                              htmlFor={"Categories" + index}
                            >
                              Categories
                            </label>
                            <Select
                              isClearable
                              isSearchable
                              options={categoriesOptions}
                              value={formik.values?.products?.[index]?.category ? { label: formik.values?.products?.[index]?.category.name, value: formik.values?.products?.[index]?.category}: null}
                              onChange={(e2) => {
                                formik.setFieldValue(`products[${index}].category`, e2.value);
                                setSubCategoryOptionsHandler(e2.value?.subCategory)
                                setSelectedCategory({...selectedCategory, _id:e2?.value?._id})
                              }}
                            />
                           
                            {formik.touched?.products?.[index]?.category && formik.errors?.products?.[index]?.category ? (<div className='req-error'>{formik.errors?.products?.[index]?.category}</div>) : null}
                          </div>
                          <div className="col-lg-6">
                            <label htmlFor={"subcategory" + index} className="form-label">Sub categories</label>
                           
                            <Select
                              // isClearable
                              isSearchable
                              options={subCategoryOptions}
                              value={formik.values?.products?.[index]?.subcategory ? { label: formik.values?.products?.[index]?.subcategory?.name, value: formik.values?.products?.[index]?.subcategory } : null}
                              onChange={(e2) => {
                                formik.setFieldValue(`products[${index}].subcategory`, e2.value)
                              }}
                            />

                            {formik.touched?.products?.[index]?.subcategory && formik.errors?.products?.[index]?.subcategory ? (<div className='req-error'>{formik.errors?.products?.[index]?.subcategory}</div>) : null}
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-lg-6">
                            <label
                              className="form-label text-start d-block"
                              htmlFor={"price" + index}
                            >
                              Price
                            </label>
                            <div className="position-relative">
                              <input className="form-control" type="text" id={"price" + index} placeholder="Enter price" name="price" value={formik.values?.products?.[index]?.price} onChange={(e) => { handleChapterChange(index, "price", e.target.value) }} onBlur={() => { handleChapterBlur(index, "price") }} />
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cash input-icon" viewBox="0 0 16 16">
                                <path d="M8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
                                <path d="M0 4a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V4zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V6a2 2 0 0 1-2-2H3z" />
                              </svg>
                              {formik.touched?.products?.[index]?.price && formik.errors?.products?.[index]?.price ? (<div className='req-error'>{formik.errors?.products?.[index]?.price}</div>) : null}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <label
                              className="form-label text-start d-block"
                              htmlFor={"deletedPrice" + index}
                            >
                              Deleted price 
                            </label>
                            <div className="position-relative">
                              <input className="form-control" type="text" id={"deletedPrice" + index} placeholder="Enter deleted price" name="deletedPrice" value={formik.values?.products?.[index]?.deletedPrice} onChange={(e) => { handleChapterChange(index, "deletedPrice", e.target.value) }} onBlur={() => { handleChapterBlur(index, "deletedPrice") }} />
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cash input-icon" viewBox="0 0 16 16">
                                <path d="M8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
                                <path d="M0 4a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V4zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V6a2 2 0 0 1-2-2H3z" />
                              </svg>
                              {formik.touched?.products?.[index]?.deletedPrice && formik.errors?.products?.[index]?.deletedPrice ? (<div className='req-error'>{formik.errors?.products?.[index]?.deletedPrice}</div>) : null}
                            </div>
                          </div>

                        </div>
                        <div className="row mb-3">
                          <div className="col-lg-4">
                            <label htmlFor={"discount" + index} className="form-label">Discount %</label>
                            <div className="position-relative">
                              <input className="form-control" type="text" disabled id={"discount" + index} placeholder="Enter discount" name="discount" value={formik.values?.products?.[index]?.discount} onChange={(e) => { handleChapterChange(index, "discount", e.target.value) }} onBlur={() => { handleChapterBlur(index, "discount") }} />
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-tag-fill input-icon" viewBox="0 0 16 16">
                                <path d="M2 1a1 1 0 0 0-1 1v4.586a1 1 0 0 0 .293.707l7 7a1 1 0 0 0 1.414 0l4.586-4.586a1 1 0 0 0 0-1.414l-7-7A1 1 0 0 0 6.586 1H2zm4 3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                              </svg>
                              {formik.touched?.products?.[index]?.discount && formik.errors?.products?.[index]?.discount ? (<div className='req-error'>{formik.errors?.products?.[index]?.discount}</div>) : null}
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <label
                              className="form-label text-start d-block"
                              htmlFor={"size" + index}
                            >
                              Size
                            </label>
                            <CreatableSelect
                              isMulti
                              name="colors"
                              options={sizeOptions}
                              value={formik.values?.products?.[index]?.size?.map(ele => ({ label: ele, value: ele }))}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={(ele) => {
                                if (ele?.slice(-1)?.[0]?.__isNew__) addproductSize(ele?.slice(-1)[0].value?.toUpperCase())
                                formik.setFieldValue(`products[${index}].size`, ele?.map(ele => ele.value?.toUpperCase()))

                              }}
                              
                            />

                            {formik.touched?.products?.[index]?.size && formik.errors?.products?.[index]?.size ? (<div className='req-error'>{formik.errors?.products?.[index]?.size}</div>) : null}
                          </div>

                          <div className="col-lg-4">
                            <label htmlFor={"remainingIs" + index} className="form-label">Remaining</label>
                            <div className="position-relative">
                              <input className="form-control" type="text" id={"remainingIs" + index} placeholder="Enter name" name="remainingIs" value={formik.values?.products?.[index]?.remainingIs} onChange={(e) => { handleChapterChange(index, "remainingIs", e.target.value) }} onBlur={() => { handleChapterBlur(index, "remainingIs") }} />
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list input-icon" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                              </svg>
                              {formik.touched?.products?.[index]?.remainingIs && formik.errors?.products?.[index]?.remainingIs ? (<div className='req-error'>{formik.errors?.products?.[index]?.remainingIs}</div>) : null}
                            </div>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col-lg-12">
                            <label htmlFor={"courseThumbnail" + index} className="form-label">Product image</label>
                            <div className="d-flex justify-content-between align-items-center">
                              <div className='d-flex'>
                                {
                                  formik.values.products?.[index]?.productImage?.map?.((el, ind, arr) => {
                                    return (
                                      <div className="position-relative" key={"kdsfsj" + ind}>
                                        {<img src={el ? ImageUrl + el : process.env.PUBLIC_URL + "/images/image.svg"} width="70px" height="50px" style={{ objectFit: "contain" }} className="rounnded" alt="Selected file" />}
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-camera pro-camera" viewBox="0 0 16 16">
                                          <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />
                                          <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x xmark" viewBox="0 0 16 16" onClick={() => { (formik.values.products?.[index]?.productImage.length > 1) && formik.setFieldValue(`products[${index}].productImage`, arr?.filter?.((e, i) => ind !== i)) }} >
                                          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                      </div>
                                    )
                                  })
                                }
                              </div>

                              <div className="btn_upload">
                                <input type="file" id={"courseThumbnail" + index} className="form-control" name="thumbnail" accept="image/*" multiple onChange={(e) => { handleThumbnail(index, e) }} />
                                Upload
                              </div>
                            </div>
                            <br />
                            {formik.touched?.products?.[index]?.productImage && (formik.errors?.products?.[index]?.productImage || fileTypeError.image?.trim()?.length > 0) ? (<div className='req-error'>{formik.errors?.products?.[index]?.productImage}</div>) : null}
                          </div>
                        </div>

                        < >

                          {formik.values?.products?.[index]?.color.map((ele2, index2) => {
                            return (
                              <div className='row mt-3' key={"slkjdfow4hkn" + index2}>
                                <div className="col-lg-5">
                                  <label htmlFor={"color" + index} className="form-label">Color</label>
                                  <div className="position-relative">
                                    <input className="form-control" type="text" id={"color" + index} placeholder="Enter colour" name="color" value={formik.values?.products?.[index]?.color?.[index2]?.color} onChange={(e) => { formik.setFieldValue(`products[${index}].color[${index2}].color`, e.target.value) }} onBlur={() => { formik.setFieldTouched(`products[${index}].color[${index2}].color`, true) }} />
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-palette input-icon" viewBox="0 0 16 16">
                                      <path d="M8 5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm4 3a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM5.5 7a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm.5 6a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                                      <path d="M16 8c0 3.15-1.866 2.585-3.567 2.07C11.42 9.763 10.465 9.473 10 10c-.603.683-.475 1.819-.351 2.92C9.826 14.495 9.996 16 8 16a8 8 0 1 1 8-8zm-8 7c.611 0 .654-.171.655-.176.078-.146.124-.464.07-1.119-.014-.168-.037-.37-.061-.591-.052-.464-.112-1.005-.118-1.462-.01-.707.083-1.61.704-2.314.369-.417.845-.578 1.272-.618.404-.038.812.026 1.16.104.343.077.702.186 1.025.284l.028.008c.346.105.658.199.953.266.653.148.904.083.991.024C14.717 9.38 15 9.161 15 8a7 7 0 1 0-7 7z" />
                                    </svg>
                                    {formik.touched?.products?.[index]?.color?.[index2]?.color && formik.errors?.products?.[index]?.color?.[index2]?.color ? (<div className='req-error'>{formik.errors?.products?.[index]?.color?.[index2]?.color}</div>) : null}
                                  </div>
                                </div>
                                <div className="col-lg-5">
                                  <div className='d-flex'><label htmlFor="profile" className="form-label">Color image </label></div>
                                  <div>
                                    <div className="d-flex justify-content-between align-items-center">
                                      <div className="position-relative">

                                        {<img src={formik.values?.products?.[index]?.color?.[index2]?.colorImage ? ImageUrl + formik.values?.products?.[index]?.color?.[index2]?.colorImage : process.env.PUBLIC_URL + "/images/image.svg"} width="50px" height="50px" className="rounded" alt="Selected file" />}
                                        {
                                          <>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-camera pro-camera" viewBox="0 0 16 16">
                                              <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />
                                              <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                                            </svg>
                                            {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x xmark" viewBox="0 0 16 16" onClick={() => { formik.setFieldValue("logo", "others/person-circle.svg") }} >
                                              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                            </svg> */}
                                          </>
                                        }
                                      </div>
                                      <div className="btn_upload">
                                        <input type="file" id="upload_file" accept="image/*" className="form-control" name="profileImage" onChange={(e) => { colorImageUpload(e, index, index2) }} />
                                        Upload
                                      </div>


                                    </div>
                                    {formik.touched?.products?.[index]?.color?.[index2]?.colorImage && formik.errors?.products?.[index]?.color?.[index2]?.colorImage ? (<div className='req-error'>{formik.errors?.products?.[index]?.color?.[index2]?.colorImage}</div>) : null}
                                  </div>

                                </div>
                                <div className="col-2 d-flex justify-content-center align-items-center">

                                  <div className=" text-end mb-3">
                                    {(formik.values?.products?.[index]?.color?.length === (index2 + 1) && formik.values?.products?.[index]?.color?.length < 5) && <button type="button" className="me-3 plusButton" onClick={(e) => { addNewColor(e, index) }}><span>+</span></button>}
                                    {formik.values?.products?.[index]?.color?.length !== 1 && <button type="button" className=" plusButton" onClick={(e) => { removeColor(e, index, index2) }}><span>-</span></button>}
                                  </div>
                                </div>
                              </div>
                            )
                          })
                          }
                        </>

                        <label htmlFor="description" className="form-label">Description</label>
                        <div className="position-relative">
                          <JoditEditor
                            ref={editor}
                            value={formik.values?.products?.[index]?.desc}
                            onBlur={(e) => {
                              formik.setFieldTouched("description", true)
                              handleChapterChange(index, "desc", e)
                            }}
                            config={config}
                          />
                          {formik.touched?.products?.[index]?.desc && formik.errors?.products?.[index]?.desc ? (<div className='req-error'>{formik.errors?.products?.[index]?.desc}</div>) : null}
                        </div>
                      </div>
                    </div></div>)}
                <br />
                <div className="text-center d-flex justify-content-around">
                  <button type="submit" className="btn-custom w-auto mt-2" disabled={addSpin} >{addSpin ? <Spinwheel sizeClass="spin-small" /> : "Submit"}</button>
                  <div className="btn-custom w-auto mt-2" onClick={() => { window.history.back(); }} >Cancel</div>
                </div>
              </form>
              <br />
            </div>
            <div className="col-lg-8"></div>
          </div>
        </div>
      </div> 
      <Category modalId={categoryModalId} formik={categoryFormik} addLoader={submitLoader}  />
      <SubCategory modalId={subCategoryModalId} addLoader={submitLoader} formik={subCategoryFormik} />
    </>
  );
}

export default ProductAdd
import React, { useEffect, useState } from 'react'
import moment from 'moment';
import { ImageUrl } from '../../../../utils/BaseUrl';
import { Link } from 'react-router-dom';
import { getFlightBookingApi } from '../../../../utils/ApiEndpointFunctions';
import Spinwheel from '../../../common/Spinwheel';

function ViewFlightBookingModal({ modalId,id }) {
   const [data,setData] = useState({})
   const [loading, setLoading] = useState(true)


   const getFlightBooking = async () => {
      try {
         const res = await getFlightBookingApi(id)
         if (res.status === 200 && res.data?.data) {
            setData(res.data?.data);
         }
         setLoading(false)
      } catch (error) {
         // setLoading(true)
      }
   }

   useEffect(() => {
      if (id) {
         getFlightBooking()
      }
   }, [id])


   return (
      <div className="modal fade" id={modalId} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
         <div className="modal-dialog modal-xl modal-dialog-centered">
            <div className="modal-content">
               <div className="modal-header border-0">
                  <button type="button" className="btn-close block-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div className="modal-body">
                  <div className="col-lg-12" style={{ zIndex: "1" }}>
                     <div action="" className='p-4' >
                        <h1 className='text-center'>
                           Booking
                        </h1>
                        {loading ?
                           <Spinwheel /> :
                        <div className="row">
                           <div className="mb-3">
                              <h4 className='fw-bold'>User name</h4>
                              <p className='text-capitalize mb-0'>{data?.userId?.name || "-"}</p>
                           </div>
                           <h2 className='fw-bold text-center'>Details</h2>
                           <div className="row row-cols-lg-3 row-cols-md-2">
                              <div className="mb-3">
                                 <h4 className='fw-bold'>PNR no.</h4>
                                 <div className='position-relative'>
                                    <p className='mb-0 text-break'>{data?.bookingId || "-"}</p>
                                 </div>
                              </div>
                              <div className="mb-3">
                                 <h4 className='fw-bold'>Departure</h4>
                                 <div className='position-relative'>
                                    <p className='mb-0'>{data?.departure || "-"}</p>
                                 </div>
                              </div>
                           
                              <div className="mb-3">
                                 <h4 className='fw-bold'>Arrival</h4>
                                 <div className='position-relative'>
                                    <p className='mb-0'>{data?.arrival || "-"}</p>
                                 </div>
                              </div>
                              <div className="mb-3">
                                 <h4 className='fw-bold'>Departure Date</h4>
                                 <div className='position-relative'>
                                    <p className='mb-0'>{data?.departureDate ? moment(data?.departureDate).format("DD/MM/YYYY h:mm:ss a") : "-"}</p>
                                 </div>
                              </div>
                              <div className="mb-3">
                                 <h4 className='fw-bold'>Amount</h4>
                                 <p className='text-capitalize mb-0'>{data?.amount || "-"}</p>
                              </div>
                              <div className="mb-3">
                                 <h4 className='fw-bold'>Seats</h4>
                                 <p className='text-capitalize mb-0'>{data?.noOfSeats || "-"}</p>
                              </div>
                           </div>
                           <hr />
                           <h2 className='fw-bold text-center'>Payment</h2>
                           <div className="row row-cols-lg-3 row-cols-md-2">
                              <div className="mb-3">
                                 <h4 className='fw-bold'>Amount</h4>
                                 <p className='mb-0'>{data?.payment?.amount}</p>
                              </div>
                              <div className="mb-3">
                                 <h4 className='fw-bold'>Status</h4>
                                 <p className='mb-0'>{data?.payment?.status == "success" ? "Yes" : "No"}</p>
                              </div>
                           </div>

                           <hr />
                           <h2 className='fw-bold text-center'>Booking</h2>
                           <div className="row row-cols-lg-3 row-cols-md-2">
                                 <div>
                                    <h4 className='fw-bold'>Booking date</h4>
                                    <p className='mb-0'>{moment(data?.createdAt).format("DD/MM/YYYY h:mm:ss a")}</p>
                                 </div>
                              <div>
                                 <h4 className='fw-bold'>Status</h4>
                                 <p className='mb-0'>{data?.status}</p>
                              </div>
                              <div>
                                 {data?.ticketFile && <div>
                                    <h4 className='fw-bold'>Attachment</h4>
                                    <Link to={ImageUrl + data?.ticketFile} target='_blank'>
                                       View
                                    </Link>
                                 </div>}
                              </div>
                           </div>
                           <hr />
                           <h2 className='fw-bold text-center'>Travelers</h2>
                           <div className="row row-cols-md-6">
                              <div>
                                 <h4 className='fw-bold'>Name</h4>
                              </div>
                              <div>
                                 <h4 className='fw-bold'>Email</h4>
                              </div>
                              <div>
                                 <h4 className='fw-bold'>DOB</h4>
                              </div>
                              <div>
                                 <h4 className='fw-bold'>Gender</h4>
                              </div>
                              <div>
                                 <h4 className='fw-bold'>Phone no.</h4>
                              </div>
                              <div>
                                 <h4 className='fw-bold'>Purpose</h4>
                              </div>
                              {data?.details?.travelers?.map(traveler => <>
                                 <div>
                                    <p className='mb-0 text-break'>{traveler?.name?.firstName} {traveler?.name?.lastName}</p>
                                 </div>
                                 <div>
                                    <p className='mb-0 text-break'>{traveler?.contact?.emailAddress}</p>
                                 </div>
                                 <div>
                                    <p className='mb-0 text-break'>{traveler?.dateOfBirth}</p>
                                 </div>
                                 <div>
                                    <p className='mb-0 text-break'>{traveler?.gender}</p>
                                 </div>
                                 <div>
                                    <p className='mb-0 text-break'>{traveler?.contact?.phones?.[0]?.countryCallingCode} {traveler?.contact?.phones?.[0]?.number}</p>
                                 </div>
                                 <div>
                                    <p className='mb-0 text-break'>{traveler?.contact?.purpose}</p>
                                 </div>
                              </>)}
                           </div>
                        </div>}
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default ViewFlightBookingModal
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useAdminContext } from '../../../../context/adminContext';
import {  createOrUpdateTrainApi,  getAllTrainPGApi,  removeTrainByIdApi, } from '../../../../utils/ApiEndpointFunctions';
import Header from '../../../common/Header';
import { trainInitialValue, trainValidationSchema } from "../../../common/Validation"
import Pagination from '../../../common/Pagination';
import Spinwheel from '../../../common/Spinwheel';
import { ImageUrl } from '../../../../utils/BaseUrl';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { toast } from 'react-hot-toast';
import { debounce } from 'lodash';
import moment from 'moment';
import AddOrUpdateTrainModal from './AddTrainModal';
import RemoveModal from '../../../common/RemoveModal';


function AllTrain() {
  const navigate = useNavigate()
  const [tableSpin, setTableSpin] = useState(true);
  const [data, setData] = useState([]);
  let [selectedPage, setSelectPage] = useState(0);
  const [page, setPage] = useState(0);
  const addOrUpdateTrainModalId = "addOrUpdateTrainModalId"
  const trainRemoveModalId = "trainRemoveModalId"
  const [selectedTrain, setSelectedTrain] = useState(null)
  const [addLoader, setAddLoader] = useState(false)
  const limit = 10;

  const getAlTrainList = async (selectedPage, search) => {
    try {
      setTableSpin(false)
      const res = await getAllTrainPGApi(search || "", selectedPage + 1, limit)
      if (res.status === 200) {
        setData(res.data?.data?.data)
        const totaldata = res.data?.data?.totalData

        setPage(Math.ceil(totaldata / limit));
      }
      setTableSpin(true)
    } catch (error) {
      setTableSpin(true)
    }
  }

  const handlePageClick = (event) => {
    setSelectPage(event.selected);
    getAlTrainList(event.selected)
  };

  const handleRemoveMovie = async () => {
   try {
     const res = await removeTrainByIdApi(selectedTrain);
     if (res?.status === 200) {
       toast.success(res.data?.message)
       getAlTrainList(0)
       setSelectedTrain(null)
       document.getElementById(trainRemoveModalId).click()
     }
   } catch (error) {
     toast.error(error?.response?.data?.message);
   }
 }

  const handleSubmit = async (values) => {
    try {
      setAddLoader(true)
      let payload = {
        ...values,
        arrivalPlace: values.arrivalPlace.value,
        departurePlace: values.departurePlace.value,
      }
      const res = await createOrUpdateTrainApi(payload);
      if (res?.status === 200) {
        toast.success(res.data?.message)
        setAddLoader(false)
        getAlTrainList(0)
        document.getElementById(addOrUpdateTrainModalId).click()
        setSelectedTrain(null)
       formik.resetForm()
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setAddLoader(false)
    }
  }


  const formik = useFormik({
    initialValues: trainInitialValue,
    onSubmit: handleSubmit,
    validationSchema: trainValidationSchema,
  })

  const onChange = async (e) => {
    let search = e.target.value;
    getAlTrainList(0, search)
  };

  const debouncedOnChange = debounce(onChange, 500);

  useEffect(() => {
    getAlTrainList(selectedPage)
  }, [])




  return (
    <>
      {/*<Sidebar />*/}
      <div className="main-content vh-100 vh_md-auto overflow-y-a">
        <Header title="Train" />
        {/* CARDS */}
        <div className="container-fluid">
          <div className="row">
            <nav className="mb-5">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">
                    <i className="fe fe-home"></i> Dashboard
                  </Link>
                </li>
                <Link to={"/ticket/train/tickets"} className="breadcrumb-item active" aria-current="page">
                Train Ticket
                </Link>
                <li className="breadcrumb-item active" aria-current="page">
                Trains
                </li>
              </ol>
            </nav>
            <div className="mb-4" style={{ textAlign: "end" }}>
              <div className='d-flex justify-content-end gap-2' style={{ display: "-webkit-inline-box" }}>
                <div className='d-flex gap-2 align-items-center justify-content-center'>
                <button onClick={()=>navigate("/ticket/train/train/carousel")} className="btn-custom w-auto" style={{ cursor: "pointer" }}>
                  Carousel
                  </button>
                <button data-bs-toggle="modal" data-bs-target={`#${addOrUpdateTrainModalId}`} onClick={() => {formik.resetForm() }} className="btn-custom w-auto" style={{ cursor: "pointer" }}>
                  Add
                  </button>
                  <button onClick={()=>navigate("/ticket/train/all-station")} className="btn-custom w-auto" style={{ cursor: "pointer" }}>
                  Stations
                  </button>
                  <div className="position-relative">
                    <input
                      className="form-search me-3"
                      type="search"
                      name=""
                      onChange={debouncedOnChange}
                      placeholder="Search"
                    />
                    <img
                      className="users-search"
                      src="/images/Icon feather-search.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr />

            <div className="card p-3 table-responsive" >
              <table className="table table-hover table-nowrap card-table">
                <thead>
                  <tr>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        S.No
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        Name
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        Number
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        Departure
                      </span>
                    </th>
                  
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        Arrival
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                       Departure Date
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                       Arrival Date
                      </span>
                    </th>
                    <th></th>


                  </tr>
                </thead>
                {
                  tableSpin ?
                    <tbody className="list fs-base">
                      {data?.length > 0 ? data?.map((ele, index) => {
                        return (<tr key={index} >
                          <td>{index + 1}</td>
                          <td className='position-relative'>
                            <div className="avatar avatar-xs align-middle me-2">
                              <img
                                className="avatar-img rounded-circle"
                                src={ele?.image && ele?.image !== "null" ? ImageUrl + encodeURIComponent(ele?.image) : "/images/person-circle.svg"}
                                alt="..."
                              />
                            </div>
                            {ele?.trainName?.length > 30 ? <span>
                              <div className="tooltip1 item-name text-reset" >
                              {ele?.trainName?.substr(0, 30)}...
                              <div className='wrapper'>
                                <div className="tooltip1-text">{ele?.trainName}</div>
                              </div>
                              </div>
                            </span> :
                              <span>{ele?.trainName || "-"}</span>}
                          </td>
                          <td className='position-relative'>
                            {ele?.trainNumber || "-"}
                          </td>
                          <td className='position-relative'>
                            {ele?.departurePlace?.name || "-"}
                          </td>
                          <td className='position-relative'>
                            {ele?.arrivalPlace?.name || "-"}
                          </td>
                          <td className='position-relative'>
                            <span>{ele?.departureTime ? moment(ele?.departureTime).format("DD/MM/YYYY h:mm:ss a") : "-"}</span>
                          </td>
                          <td className='position-relative'>
                            <span>{ele?.arrivalTime ? moment(ele?.arrivalTime).format("DD/MM/YYYY h:mm:ss a") : "-"}</span>
                          </td>
                          <td className="text-end">
                            {ele?.status?.toLowerCase() != "transferred" &&
                              <div className="dropdown">
                                <span
                                  className="dropdown-ellipses dropdown-toggle"
                                  role="button"
                                  data-bs-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <i className="fe fe-more-vertical" />
                                </span>
                                <div className="dropdown-menu dropdown-menu-end">
                                  <div data-bs-toggle="modal" data-bs-target={`#${addOrUpdateTrainModalId}`} className=' dropdown-item' style={{ cursor: "pointer" }} onClick={() => { formik.setValues({ ...ele, arrivalPlace: { label: ele.arrivalPlace.name, value: ele.arrivalPlace._id }, departurePlace: { label: ele.departurePlace.name, value: ele.departurePlace._id } }) }}>
                                    View / edit
                                  </div>
                                  <Link to={`/ticket/train/train/coach/${ele?._id}`} className=' dropdown-item' style={{ cursor: "pointer" }} >
                                    View / add coach
                                  </Link>
                                  <Link to={`/ticket/train/train/stop/${ele?._id}`} className=' dropdown-item' style={{ cursor: "pointer" }} >
                                    View / add stop
                                  </Link>
                                  <div data-bs-toggle="modal" data-bs-target={`#${trainRemoveModalId}`} className=' dropdown-item' style={{ cursor: "pointer" }} onClick={() => { setSelectedTrain(ele?._id) }}>
                                    Remove
                                  </div>
                                </div>
                              </div>}
                          </td>
                        </tr>
                        )
                      }) : <tr align="center">
                        <td colSpan="9">
                          <h1>
                            Data Not Found
                          </h1>
                        </td>
                      </tr>}
                    </tbody> : (
                      <tbody>
                        <tr align="center">
                          <td colSpan="9">
                            <Spinwheel />
                          </td>
                        </tr>
                      </tbody>
                    )
                }
              </table>
            </div>
            <div className="d-flex justify-content-center">
              {data?.length > 0 ? <Pagination
                clickHandler={handlePageClick}
                page={page}
                selectedPage={selectedPage}
              />
                : null}
            </div>
          </div>
          <AddOrUpdateTrainModal modalId={addOrUpdateTrainModalId} formik={formik} addLoader={addLoader}/>
          <RemoveModal removeHandler={handleRemoveMovie} modalId={trainRemoveModalId} message={"Are you sure you want to remove?"} />
        </div>
      </div>
    </>
  )
}

export default AllTrain
import React from 'react'
import Spinwheel from '../../../common/Spinwheel'
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import AsyncSelect from 'react-select/async';
import { getAllMovieCinemaApi, getAllMovieLanguageApi } from '../../../../utils/ApiEndpointFunctions';

function AddOrViewMovieShow({ formik, modalId, addLoader }) {

  const languageOptions = async () => {
    try {
      const res = await getAllMovieLanguageApi()
      
      return res.data?.data?.map(lang => { return { label: lang?.name, value: lang?.name } })
    } catch (error) {
      console.log(error);
      return []
    }
  }

  const cinemaOptions = async () => {
    try {
      const res = await getAllMovieCinemaApi()
     
      return res.data?.data?.map(cinema => { return { label: cinema?.name, value: cinema?._id } })
    } catch (error) {
      console.log(error);
      return []
    }
  }
 

  return (
    <div className="modal fade" id={modalId} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-sm modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header border-0">
            <button type="button" className="btn-close block-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="col-lg-12" style={{ zIndex: "1" }}>
              <form action="" className='p-4' >
                <h2 className='text-center'>
                  Show
                </h2>
                <div className='d-flex flex-column gap-2'>
                  <div>
                    <div className='mb-2'>
                      <label htmlFor='language'>Language</label>
                      <AsyncSelect cacheOptions className='' defaultOptions onChange={(e) => formik.setFieldValue("language", e?.value)} value={formik.values?.language && { label: formik.values?.language, value: formik.values?.language }} loadOptions={languageOptions} />
                      {formik.touched?.language ? (<div className='req-error'>{formik.errors?.language}</div>) : null}
                    </div>
                    <div className=''>
                      <label htmlFor='Cinema'>Cinema</label>
                      <AsyncSelect cacheOptions className='' defaultOptions onChange={(e) => formik.setFieldValue("movieCinemaId", { label: e?.label, value: e?.value })} value={{ value: formik.values?.movieCinemaId.value, label: formik.values?.movieCinemaId.label }} loadOptions={cinemaOptions} />
                      {formik.touched?.movieCinemaId ? (<div className='req-error'>{formik.errors?.movieCinemaId}</div>) : null}
                    </div>
                  </div>
                  <div className="position-relative">
                    <label htmlFor='noOfSeats'>No. of seats</label>
                    <input className="form-control border border-1" type="text" id="noOfSeats" placeholder="Enter no of seats" name="noOfSeats" value={formik.values?.noOfSeats} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                    {formik.touched?.noOfSeats ? (<div className='req-error'>{formik.errors?.noOfSeats}</div>) : null}
                  </div>
                  <div className="position-relative">
                    <label htmlFor='price'>Price</label>
                    <input className="form-control border border-1" type="number" id="price" placeholder="Enter price" name="price" value={formik.values?.price} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                    {formik.touched?.price ? (<div className='req-error'>{formik.errors?.price}</div>) : null}
                  </div>
                  <div className="position-relative">
                    <label htmlFor='tax'>Tax (%)</label>
                    <input className="form-control border border-1" type="number" id="tax" placeholder="Enter tax" name="tax" value={formik.values?.tax} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                    {formik.touched?.tax ? (<div className='req-error'>{formik.errors?.tax}</div>) : null}
                  </div>
                  <div>
                    <label htmlFor='timeDate'>Show time</label>
                    <DateTimePicker name='timeDate' className="w-100 form-control border border-1" format="dd-MM-yyy h:mm:ss a" onChange={(e) => formik.setFieldValue("timeDate", e)} value={formik.values?.timeDate ? new Date(formik.values?.timeDate) : null} />
                  </div>
                </div>
                <div className="modal-footer border-0 justify-content-center pt-4">
                  <button type="button" className="btn-custom w-auto" onClick={(e) => { formik?.handleSubmit() }}  >{addLoader ? <Spinwheel sizeClass="spin-small" /> : "Save"}</button>
                  <button type="button" className="btn-custom w-auto" data-bs-dismiss='modal' >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddOrViewMovieShow
import { useFormik } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react'
import toast from 'react-hot-toast';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAdminContext } from '../../../../context/adminContext';
import Sidebar from '../../../../Layout/Sidebar';
import { uploadImage, createOrUpdateMovieApi, getAllMovieLanguageApi, createOrUpdateMovieLanguageApi, getMovieByIdApi, createOrUpdateBusRouteApi, getAllBusOperatorApi, getBusRouteByIdApi } from '../../../../utils/ApiEndpointFunctions';
import { ImageUrl } from '../../../../utils/BaseUrl';
import Spinwheel from '../../../common/Spinwheel';
import JoditEditor from 'jodit-react';
import { busRouteInitialValue, busRouteValidationSchema, } from '../../../common/Validation';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import Header from '../../../common/Header';
import TimePicker from 'react-time-picker';
import TimeField from 'react-simple-timefield';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import AsyncCreatableSelect from 'react-select/async-creatable';
import DateTimePicker from 'react-datetime-picker';
import Select from 'react-select';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import AsyncSelect from 'react-select/async';

function BusRoute() {
   const [fileTypeError, setFileTypeError] = useState({ id: null, message: "", error: 0 })
   const navigate = useNavigate()
   const param = useParams()
   const [data, setData] = useState(busRouteInitialValue)
   const [addSpin, setAddSpin] = useState(false)
   const location = useLocation();
   const [reRendor, setReRender] = useState(false);
   let path = location.pathname
   path = path.split("/").slice(-1)[0];
   path = (path[0].toUpperCase() + path.slice(1))
   const editor = useRef(null);
   const config = useMemo(() => {
      return {
         disablePlugins: ['Find', "Preview", "fullsize", "Change", "About", 'Print', "image", "file", "spellcheck", "selectall", "copyformat", "table", "hr", "symbols", "lineHeight", "classSpan", "paragraph"],
         removeButtons: ['undo', 'redo', 'Print', 'find', "video", "cut", "copy", "paste", "image", "source", "symbols", "lineHeight", "selectall", "paragraph", "superscript", "subscript"],
         placeholder: 'Start writing...',
         askBeforePasteFromWord: true,
         askBeforePasteHTML: false,
         enter: "br",
         hidePoweredByJodit: true,
         STATUSES: {
            beforeDestruct: "beforeDestruct",
            beforeInit: "beforeInit",
            destructed: "destructed",
            ready: "ready",
         }
      }
   }, []
   );



   const handleSubmit = async (values) => {
      try {
         let res
         let payload ={
            ...values,
            busOperatorId:values.busOperatorId.value,
            busType:values.busType.value,
            seatType:values.seatType.value,

         }
         if (param.id) {
            setAddSpin(true)
            res = await createOrUpdateBusRouteApi({ ...payload, _id: param?.id, })
         } else {
            setAddSpin(true)
            res = await createOrUpdateBusRouteApi(payload);
         }
         if (res?.status === 200) {
            toast.success(res.data?.message)
            setAddSpin(false)
            navigate("/ticket/bus/routes", { replace: true })
         }
      } catch (error) {
         toast.error(error?.response?.data?.message);
         setAddSpin(false)
      }
   }

   const formik = useFormik({
      initialValues: param?.id ? data : busRouteInitialValue,
      onSubmit: handleSubmit,
      validationSchema: busRouteValidationSchema,
   });




   const removeRow = (e, index,type) => {
      e.preventDefault()
      formik.setFieldValue(type, formik.values?.[type]?.filter((ele, i) => {
         if (i === index) return false;
         return true;
      }))
   }

   const addNewRow = (e, index,type) => {
      e.preventDefault();
      formik.setFieldValue(type, [...formik.values?.[type], {
         name: "",
         img: "",
         role: ""
      }])
   }


   const busOperatorOption = async () => {
      try {
         const res = await getAllBusOperatorApi()
         return res.data?.data?.map(operator => { return { label: operator?.name, value: operator?._id } })
      } catch (error) {
         console.log(error);
         return []
      }
   }


   const getBusRouteById = async () => {
      try {
         const res = await getBusRouteByIdApi(param?.id)
         const busOperator = await busOperatorOption()
         setData(res?.data?.data || busRouteInitialValue)
        
         formik.setValues(res?.data?.data || busRouteInitialValue)
         if(res?.data?.data){
            const busType = busTypeOptions.filter(ele=>ele.value==res?.data?.data?.busType)?.[0]
            if(busType){
               formik.setFieldValue("busType", busType)
            }
            const seatType = seatTypeOptions.filter(ele=>ele.value==res?.data?.data?.seatType)?.[0]
            if(seatType){
               formik.setFieldValue("seatType", seatType)
            }
            if(busOperator){
               let operator = busOperator?.filter(ele=>ele?.value==res?.data?.data?.busOperatorId?._id)?.[0]
               if(operator){
                  formik.setFieldValue("busOperatorId", operator)
               }
            }
         }
      } catch (error) {
         console.log(error);
      }
   }

   useEffect(() => {
      if(param?.id){
         getBusRouteById()
      }
   }, [param?.id])


   const busTypeOptions = [
      { label: "Non AC", value: "non-ac" },
      { label: "AC", value: "ac" }
   ]

   const seatTypeOptions = [
      { label: "Sitting", value: "sitting" },
      { label: "Sleeper", value: "sleeper" }
   ]



   return (
      <>
         {/*<Sidebar />*/}
         <div className="main-content vh-100 vh_md-auto overflow-y-a">
            <Header title={<>Bus Route</>} />
            <div className="container-fluid">
               <div className="row">
                  <nav className="mb-5">
                     <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                           <Link to="/dashboard">
                              <i className="fe fe-home"></i> Dashboard
                           </Link>
                        </li>
                        <Link to="/ticket/bus/tickets" className="breadcrumb-item active" aria-current="page">
                           Bus ticket
                        </Link>
                        <li className="breadcrumb-item active" aria-current="page">
                           Route
                        </li>
                     </ol>
                  </nav>
                  <div>
                     <h2 className="report">Bus route details</h2>
                  </div>
                  <hr />

                  <div className="col-12" style={{ zIndex: "1" }}>
                     <form action="" onSubmit={formik.handleSubmit} >
                        <div className="row row-cols-lg-3 row-cols-md-2">
                           <div>
                              <label htmlFor="departurePlace" className="form-label">Departure place</label>
                              <div className="position-relative">
                                 <input className="form-control" type="text" id="departurePlace" placeholder="Enter departure place" name="departurePlace" value={formik.values.departurePlace} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-door-fill input-icon" viewBox="0 0 16 16">
                                    <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
                                    <path d="M3 8.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0-5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-1z" />
                                 </svg>
                                 {formik.touched.departurePlace && formik.errors.departurePlace ? (<div className='req-error'>{formik.errors.departurePlace}</div>) : null}
                              </div>
                              <br />
                           </div>
                           <div>
                              <label htmlFor="departure" className="form-label">Departure time</label>
                              <DateTimePicker name='departure' className="border border-1 form-control w-100 bg-white" format="dd-MM-yyy h:mm:ss a" onChange={(e) => formik.setFieldValue("departure", e)} value={formik.values?.departure ? new Date(formik.values?.departure) : null} />
                              {formik.touched.departure && formik.errors.departure ? (<div className='req-error'>{formik.errors.departure}</div>) : null}
                           </div>

                           <div>
                              <label htmlFor="arrivalPlace" className="form-label">Arrival place</label>
                              <div className="position-relative">
                                 <input className="form-control" type="text" id="arrivalPlace" placeholder="Enter arrival place name" name="arrivalPlace" value={formik.values.arrivalPlace} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-door-fill input-icon" viewBox="0 0 16 16">
                                    <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
                                    <path d="M3 8.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0-5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-1z" />
                                 </svg>
                                 {formik.touched.arrivalPlace && formik.errors.arrivalPlace ? (<div className='req-error'>{formik.errors.arrivalPlace}</div>) : null}
                              </div>
                              <br />
                           </div>
                           <div>
                              <label htmlFor="arrivalTime" className="form-label">Arrival time</label>
                              <DateTimePicker name='arrivalTime' className="form-control border border-1 w-100 bg-white" format="dd-MM-yyy h:mm:ss a" onChange={(e) => formik.setFieldValue("arrivalTime", e)} value={formik.values?.arrivalTime ? new Date(formik.values?.arrivalTime) : null} />
                              {formik.touched.arrivalTime && formik.errors.arrivalTime ? (<div className='req-error'>{formik.errors.arrivalTime}</div>) : null}
                          
                           </div>
                              <div>
                              <label htmlFor="departure" className="form-label">Bus operator</label>
                              <AsyncSelect cacheOptions defaultOptions value={formik.values.busOperatorId} onChange={(e)=>formik.setFieldValue("busOperatorId", e)} loadOptions={busOperatorOption} />
                              {formik.touched.busOperatorId && formik.errors.busOperatorId ? (<div className='req-error'>{formik.errors.busOperatorId}</div>) : null}
                              </div>
                           <div>
                                 <label htmlFor="totalSeat" className="form-label">Total seat</label>
                              <div className="position-relative">
                                 <input className="form-control" type="text" id="totalSeat" placeholder="Enter total seat" name="totalSeat" value={formik.values.totalSeat} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-door-fill input-icon" viewBox="0 0 16 16">
                                    <path d="M0 1.5V13a1 1 0 0 0 1 1V1.5a.5.5 0 0 1 .5-.5H14a1 1 0 0 0-1-1H1.5A1.5 1.5 0 0 0 0 1.5z" />
                                    <path d="M3.5 2A1.5 1.5 0 0 0 2 3.5v11A1.5 1.5 0 0 0 3.5 16h6.086a1.5 1.5 0 0 0 1.06-.44l4.915-4.914A1.5 1.5 0 0 0 16 9.586V3.5A1.5 1.5 0 0 0 14.5 2h-11zm6 8.5a1 1 0 0 1 1-1h4.396a.25.25 0 0 1 .177.427l-5.146 5.146a.25.25 0 0 1-.427-.177V10.5z" />
                                 </svg>
                                 {formik.touched.totalSeat && formik.errors.totalSeat ? (<div className='req-error'>{formik.errors.totalSeat}</div>) : null}
                              </div>

                           </div>
                           <div>
                                 <label htmlFor="price" className="form-label">Price</label>
                              <div className="position-relative">
                                 <input className="form-control" type="text" id="price" placeholder="Enter price" name="price" value={formik.values.price} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-door-fill input-icon" viewBox="0 0 16 16">
                                    <path d="M0 1.5V13a1 1 0 0 0 1 1V1.5a.5.5 0 0 1 .5-.5H14a1 1 0 0 0-1-1H1.5A1.5 1.5 0 0 0 0 1.5z" />
                                    <path d="M3.5 2A1.5 1.5 0 0 0 2 3.5v11A1.5 1.5 0 0 0 3.5 16h6.086a1.5 1.5 0 0 0 1.06-.44l4.915-4.914A1.5 1.5 0 0 0 16 9.586V3.5A1.5 1.5 0 0 0 14.5 2h-11zm6 8.5a1 1 0 0 1 1-1h4.396a.25.25 0 0 1 .177.427l-5.146 5.146a.25.25 0 0 1-.427-.177V10.5z" />
                                 </svg>
                                 {formik.touched.price && formik.errors.price ? (<div className='req-error'>{formik.errors.price}</div>) : null}
                              </div>
                           </div>
                           <div>
                              <label htmlFor="busType" className="form-label">Bus type</label>
                              <Select
                                 className="basic-single"
                                 name="busType"
                                 options={busTypeOptions}
                                 value={formik.values.busType}
                                 onChange={(e) =>formik.setFieldValue("busType", e)}
                              />
                                 {formik.touched.busType && formik.errors.busType ? (<div className='req-error'>{formik.errors.busType}</div>) : null}
                           </div>
                           <div>
                              <label htmlFor="seatType" className="form-label">Seat type</label>
                              <Select
                                 className="basic-single"
                                 name="seatType"
                                 options={seatTypeOptions}
                                 value={formik.values.seatType}
                                 onChange={(e) => formik.setFieldValue("seatType", e)}
                              />
                           {formik.touched.seatType && formik.errors.seatType ? (<div className='req-error'>{formik.errors.seatType}</div>) : null}

                           </div>
                           </div>
                           <div className='my-3'>
                              <h3>Pickup point</h3>
                              {formik.values?.pickPoint?.map((ele2, index2) => {
                                 return (
                                    <div className='row mt-3 row-cols-md-1 row-cols-lg-3 mt-3' key={"slkjdfow4hkn" + index2}>
                                     
                                       <div className="">
                                          <div className="position-relative">
                                          <label htmlFor={"name" + index2} className="form-label">Place</label>
                                             <input className="form-control" type="text" id={"place" + index2} placeholder="Enter cast place" place="place" value={formik.values?.pickPoint?.[index2]?.place} onChange={(e) => { formik.setFieldValue(`pickPoint[${index2}].place`, e.target.value) }} onBlur={() => { formik.setFieldTouched(`pickPoint[${index2}].place`, true) }} />
                                             {formik.touched?.pickPoint?.[index2]?.place && formik.errors?.pickPoint?.[index2]?.place ? (<div className='req-error'>{formik.errors?.pickPoint?.[index2]?.place}</div>) : null}
                                          </div>
                                       </div>
                                       <div className="">
                                          <div>
                                          <label className="form-label">Time</label>
                                             <DateTimePicker name='time' className="form-control border border-1 w-100 bg-white" format="dd-MM-yyy h:mm:ss a" onChange={(e) => formik.setFieldValue(`pickPoint.[${index2}].time`, e)} value={formik.values?.pickPoint?.[index2]?.time ? new Date(formik.values?.pickPoint?.[index2]?.time) : null} />
                                          {formik.touched?.pickPoint?.[index2]?.time && formik.errors?.pickPoint?.[index2]?.time ? (<div className='req-error'>{formik.errors?.pickPoint?.[index2]?.time}</div>) : null}
                                          </div>
                                       </div>
                                       <div className="d-flex justify-content-end align-items-center">
                                          <div className=" text-end mb-3">
                                             {(formik.values?.pickPoint?.length === (index2 + 1) && formik.values?.pickPoint?.length < 5) && <button type="button" className="me-3 plusButton" onClick={(e) => { addNewRow(e, index2,"pickPoint") }}><span>+</span></button>}
                                             {formik.values?.pickPoint?.length !== 1 && <button type="button" className=" plusButton" onClick={(e) => { removeRow(e, index2,"pickPoint") }}><span>-</span></button>}
                                          </div>
                                       </div>
                                    </div>
                                 )
                              })
                              }
                           </div>
                           <div className='my-3'>
                              <h3>Drop point</h3>
                              {formik.values?.dropPoint?.map((ele2, index2) => {
                                 return (
                                    <div className='row row-cols-md-1 row-cols-lg-3 mt-3' key={"slkjdfow4hkn" + index2}>
                                       
                                       <div className="">
                                          <div className="position-relative">
                                          <label htmlFor={"name" + index2} className="form-label">Place</label>
                                             <input className="form-control" type="text" id={"place" + index2} placeholder="Enter cast place" place="place" value={formik.values?.dropPoint?.[index2]?.place} onChange={(e) => { formik.setFieldValue(`dropPoint[${index2}].place`, e.target.value) }} onBlur={() => { formik.setFieldTouched(`dropPoint[${index2}].place`, true) }} />
                                             {formik.touched?.dropPoint?.[index2]?.place && formik.errors?.dropPoint?.[index2]?.place ? (<div className='req-error'>{formik.errors?.dropPoint?.[index2]?.place}</div>) : null}
                                          </div>
                                       </div>
                                       <div className="">
                                          <div>
                                          <label className="form-label">Time</label>
                                             <DateTimePicker name='time' className="form-control border border-1 w-100 bg-white" format="dd-MM-yyy h:mm:ss a" onChange={(e) => formik.setFieldValue(`dropPoint.[${index2}].time`, e)} value={formik.values?.dropPoint?.[index2]?.time ? new Date(formik.values?.dropPoint?.[index2]?.time) : null} />
                                          {formik.touched?.dropPoint?.[index2]?.time && formik.errors?.dropPoint?.[index2]?.time ? (<div className='req-error'>{formik.errors?.dropPoint?.[index2]?.time}</div>) : null}
                                          </div>

                                       </div>
                                       <div className="col-2 d-flex justify-content-end align-items-center">
                                          <div className=" text-end mb-3">
                                             {(formik.values?.dropPoint?.length === (index2 + 1) && formik.values?.dropPoint?.length < 5) && <button type="button" className="me-3 plusButton" onClick={(e) => { addNewRow(e, index2,"dropPoint") }}><span>+</span></button>}
                                             {formik.values?.dropPoint?.length !== 1 && <button type="button" className=" plusButton" onClick={(e) => { removeRow(e, index2,"dropPoint") }}><span>-</span></button>}
                                          </div>
                                       </div>
                                    </div>
                                 )
                              })
                              }
                           </div>
                           <br />
                           <div className="text-center d-flex justify-content-around">
                              <button type="submit" className="btn-custom w-auto mt-2" disabled={addSpin} >{addSpin ? <Spinwheel sizeClass="spin-small" /> : "Save"}</button>
                              <div className="btn-custom w-auto mt-2" onClick={() => { window.history.back(); }}  >Cancel</div>
                           </div>
                     </form>
                     <br />
                  </div>
                  <div className="col-lg-8"></div>
               </div>
            </div>
         </div>
      </>
   );
}

export default BusRoute